import { gql } from '@apollo/client'

export default gql`
  query Filters {
    filters @client {
      latitude
      longitude
      maxPrice
      minPrice
      duration
      makesModels
      bodyStyles
      fuelTypes
      productTypes
      transmission
      availableNow
      dealers
      locationId
      city
      state
      zip
      distance
      distanceUnit
    }
  }
`

// Performance optimization
// Including duration in query caused extra rerenders
export const REMOTE_FILTERS = gql`
  query Filters {
    filters @client {
      latitude
      longitude
      maxPrice
      minPrice
      duration
      makesModels
      bodyStyles
      fuelTypes
      productTypes
      transmission
      availableNow
      dealers
      locationId
      distance
      distanceUnit
    }
  }
`
