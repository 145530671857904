import _get from 'lodash/get'
import settings from '../../settings'
import { CACHE_KEYS } from '../../util/constants'
import { sessionStorageByCountry } from '../../util/storageByCountry'
import omit from 'lodash/omit'

export function getDefaultData() {
  return {
    filters: {
      distance:
        typeof settings.defaultDistanceFilter !== 'undefined'
          ? settings.defaultDistanceFilter
          : 50,
      distanceUnit: settings.country === 'US' ? 'MI' : 'KM',
      latitude: 0,
      longitude: 0,
      maxPrice: null,
      minPrice: null,
      duration: _get(
        settings,
        'defaultDurationFilter',
        _get(settings, 'pricingOptions[0].duration', 720)
      ),
      makesModels: [],
      bodyStyles: [],
      fuelTypes: [],
      productTypes: [],
      transmission: [],
      availableNow: false,
      dealers: [],
      locationId: null,
      city: null,
      state: null,
      zip: null,
      __typename: 'Filters',
    },
  }
}

export const getDefaultFilters = (excludedDefaultFiltersFields) => {
  return omit(getDefaultData().filters, excludedDefaultFiltersFields)
}

export const DEFAULT_FILTER_DISTANCE_RADIUS = [
  'Any Distance',
  10,
  25,
  50,
  75,
  100,
  200,
  300,
  400,
  500,
]

export function set(filterData) {
  sessionStorageByCountry.setItem(
    CACHE_KEYS.vehicleFilters,
    JSON.stringify(filterData)
  )
}

export function reset() {
  set(getDefaultFilters())
}

export function get() {
  const cachedItem = sessionStorageByCountry.getItem(CACHE_KEYS.vehicleFilters)

  if (!cachedItem) {
    return null
  }
  return JSON.parse(cachedItem)
}
