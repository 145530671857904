import { appConfig } from './appConfig'

const config = {
  defaults: {
    DEFAULT_UNITS: 'metric',
    ENABLE_I18N_DEBUG: false,
    API_HOST: `${appConfig.apiHost}`,
    PUBLIC_PATH: appConfig.publicPath,
    RECAPTCHA_KEY: appConfig.reCaptchaKey,
    RECAPTCHA_V2_KEY: appConfig.reCaptchaV2Key,
  },
}

const reactAppPrefix = 'REACT_APP_'

const AppConfig = {
  ...config.defaults,
  ...Object.entries(process.env)
    .filter(([env]) => env.startsWith(reactAppPrefix))
    .reduce(
      (obj, [env, value]) => ({
        ...obj,
        [env.replace(reactAppPrefix, '')]: value,
      }),
      {}
    ),
}

export { AppConfig }
